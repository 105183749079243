/* eslint-disable no-console, max-lines*/
/* global google */

export class DealerLocatorMap {
  /**
   * Checks if the dealer locator map is on the page.
   * @returns {boolean} True if the dealer locator is on the page, false otherwise.
   */
  static isOnPage() {
    return $('.dealer-locator').length > 0;
  }

  /**
   * Initializes the DealerLocatorMap instance.
   */
  constructor() {
    this.map = null;
    this.infoWindow = null;
    this.selectedLocation = null;
    this.locations = [];
    this.markerLocationPairs = [];
    this.attachEvents();
  }

  /**
   * Initializes the Google Map, sets the default center, zoom and country code, fetches dealer locations,
   * and enables search functionality for locating dealers.
   */
  initMap() {
    const defaultCenter = this.getCountryCenter();

    this.map = new google.maps.Map($('.dealer-locator-map')[0], {
      center: defaultCenter.center,
      zoom: defaultCenter.zoom,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
    });

    this.infoWindow = new google.maps.InfoWindow();

    $.ajax({
      url: '/DealerLocator/Dealers',
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    })
      .done((data) => {
        this.locations = data;
        this.createMarkers(false);
      });

      this.initAutocomplete();
  }

  initAutocomplete() {
    const { countryCode } = this.getCountryCenter();

    const input = document.querySelector('.dealer-locator-search-input');
    if (!input) { return; }

    let sessionToken = new google.maps.places.AutocompleteSessionToken();

    const options = {
        componentRestrictions: { country: countryCode },
        fields: ['address_components', 'geometry', 'name'],
        sessionToken: sessionToken
    };

    const autocomplete = new google.maps.places.Autocomplete(input, options);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (place.address_components) {
          const countryComponent = place.address_components.find(component => component.types.includes('country'));

          if (!countryComponent || countryComponent.short_name.toLowerCase() !== countryCode) {
              input.value = '';

              return;
          }
      }

      if (place.geometry && place.geometry.location) {
        this.selectedLocation = place.geometry.location;

        sessionToken = new google.maps.places.AutocompleteSessionToken();
      }
    });
  }

  /**
   * Clears all map markers.
   */
  clearMarkers() {
    this.markerLocationPairs.forEach(({ marker }) => {
      marker.setMap(null);
    });
  }

  /**
   * Retrieves the default map center, zoom level and country code based on the country.
   * Supports predefined locations for specific countries and defaults to a world view.
   *
   * Supported countries: USA (jobst-usa.com), Germany, France, Poland, Italy, United Kingdom,
   * Portugal, Canada, Spain, Czech Republic, Belgium, Austria, Sweden, Norway.
   *
   * @returns {Object} The center coordinates, zoom level and country code for the country.
   */
  getCountryCenter() {
    const domain = window.location.hostname.split('.').pop();
    let countryData;

    if (window.location.hostname.includes('jobst-usa.com')) {
      countryData = {
        center: { lat: 37.0902, lng: -95.7129 }, // USA
        zoom: 5,
        countryCode: 'us'
      };
    } else {
      const domainToCountryData = {
        de: { center: { lat: 51.1657, lng: 10.4515 }, zoom: 6, countryCode: 'de' }, // Germany
        fr: { center: { lat: 46.6034, lng: 1.8883 }, zoom: 6, countryCode: 'fr' }, // France
        pl: { center: { lat: 51.9194, lng: 19.1451 }, zoom: 6, countryCode: 'pl'  }, // Poland
        it: { center: { lat: 41.8719, lng: 12.5674 }, zoom: 6, countryCode: 'it'  }, // Italy
        uk: { center: { lat: 55.3781, lng: -3.4360 }, zoom: 6, countryCode: 'gb'  }, // United Kingdom
        pt: { center: { lat: 39.3999, lng: -8.2245 }, zoom: 6, countryCode: 'pt'  }, // Portugal
        ca: { center: { lat: 56.1304, lng: -106.3468 }, zoom: 4, countryCode: 'ca'  }, // Canada
        es: { center: { lat: 40.4637, lng: -3.7492 }, zoom: 6, countryCode: 'es'  }, // Spain
        cz: { center: { lat: 49.8175, lng: 15.4730 }, zoom: 7, countryCode: 'cz'  }, // Czech Republic
        be: { center: { lat: 50.8503, lng: 4.3517 }, zoom: 8, countryCode: 'be'  }, // Belgium
        at: { center: { lat: 47.5162, lng: 14.5501 }, zoom: 7, countryCode: 'at'  }, // Austria
        se: { center: { lat: 60.1282, lng: 18.6435 }, zoom: 5, countryCode: 'se'  }, // Sweden
        no: { center: { lat: 60.4720, lng: 8.4689 }, zoom: 5, countryCode: 'no'  },  // Norway
        au: { center: { lat: -25.2744, lng: 133.7751 }, zoom: 4, countryCode: 'au' } // Australia
      };

      countryData = domainToCountryData[domain] || {
        center: { lat: 0.0, lng: 0.0 }, // Default to World
        zoom: 2,
        countryCode: 'ww'
      };
    }

    return countryData;
  }

  /**
   * Creates markers for all locations on the map.
   * @param {boolean} showMarkers - Whether to display the markers on the map.
   */
  createMarkers(showMarkers) {
    const customIcon = {
      url: '/assets/img/jobst/jobst-logo.png',
      scaledSize: new google.maps.Size(70, 25),
    };

    this.locations.forEach((location) => {
      const lat = parseFloat(location.Latitude);
      const lng = parseFloat(location.Longitude);
      const latLng = new google.maps.LatLng(lat, lng);

      const marker = new google.maps.Marker({
        position: latLng,
        map: showMarkers ? this.map : null,
        title: location.Name,
        icon: customIcon,
      });

      marker.addListener('click', () => {
        this.showInfoWindow(marker, location);
      });

      this.markerLocationPairs.push({ marker, location });
    });
  }

  /**
   * Displays an info window for a specific marker.
   * @param {google.maps.Marker} marker - The marker to associate with the info window.
   * @param {Object} location - The location details for the marker.
   */
  showInfoWindow(marker, location) {
    const name = location.Name ? `<p><strong>${location.Name}</strong></p>` : '';
    const street = location.Street ? `${location.Street}, ` : '';
    const city = location.Location ? `${location.Location}, ` : '';
    const zip = location.Zip ? `${location.Zip}<br />` : '<br />';
    const phone = location.Phone ? `<a href="tel:${location.Phone}">${location.Phone}</a><br />` : '';

    const contentString = `
      <div class="google-maps-marker-container">
        ${name}
        ${street}${city}${zip}
        ${phone}
      </div>
    `;

    this.infoWindow.setContent(contentString);
    this.infoWindow.open(this.map, marker);
  }

  /**
   * Calculates the zoom level based on the distance.
   * @param {number} distance - The distance in kilometers.
   * @returns {number} The zoom level for the given distance.
   */
  getZoomLevelForDistance(distance) {
    const zoomLevels = [
      { maxDistance: 5, zoom: 14 },
      { maxDistance: 10, zoom: 13 },
      { maxDistance: 20, zoom: 12 },
      { maxDistance: 30, zoom: 11 },
      { maxDistance: 50, zoom: 10 },
      { maxDistance: 100, zoom: 8 },
      { maxDistance: 500, zoom: 7 },
      { maxDistance: Infinity, zoom: 6 },
    ];

    const zoomLevel = zoomLevels.find((level) => distance <= level.maxDistance);

    return zoomLevel ? zoomLevel.zoom : 6;
  }

  /**
   * Filters markers by distance from a given center.
   * @param {google.maps.LatLng} center - The center point.
   * @param {number} distance - The distance in kilometers.
   * @returns {Array<Object>} Filtered markers and their associated locations.
   */
  filterMarkersByDistance(center, distance) {
    if (!google.maps.geometry || !google.maps.geometry.spherical) {
      return;
    }

    const filteredMarkers = [];
    this.markerLocationPairs.forEach(({ marker, location }) => {
      const markerLatLng = marker.getPosition();
      const distanceFromCenter =
        google.maps.geometry.spherical.computeDistanceBetween(
          center,
          markerLatLng
        ) / 1000;
      if (distanceFromCenter <= distance) {
        marker.setMap(this.map);
        filteredMarkers.push({ marker, location });
      } else {
        marker.setMap(null);
      }
    });

    $('.num-of-dealers').text(filteredMarkers.length);

    return filteredMarkers;
  }

  /**
   * Displays search results on the map based on selected location, distance, and filter type.
   * Filters markers and updates the map view accordingly.
   */
  displaySearchResults() {
    if (!this.selectedLocation) {
      return;
    }

    const distance = parseFloat($('.dealer-locator-distance').val()) || 0;
    const filterType = $('.dealer-locator-filter').val() || 'Default';
    const zoomLevel = this.getZoomLevelForDistance(distance);
    const filteredMarkers = this.filterMarkersByDistance(this.selectedLocation, distance).filter(({ location }) => {

      // eslint-disable-next-line eqeqeq
      return filterType == 'Default' || (location[filterType] != undefined && location[filterType] == true);
    });

    this.clearMarkers();
    filteredMarkers.forEach(({ marker }) => {
      marker.setMap(this.map);
    });

    this.map.setCenter(this.selectedLocation);
    this.map.setZoom(zoomLevel);

    $('.num-of-dealers').text(filteredMarkers.length);

    if (filteredMarkers.length > 0) {
      const isDesktop = window.innerWidth > 992;

      if (isDesktop) {
        $('.dealer-locator-options').fadeOut(() => {
          this.showSearchResults(filteredMarkers);
        });
      } else {
        this.showSearchResults(filteredMarkers);
      }
    }
  }

  /**
   * Renders the search results in the results section.
   * @param {Array<Object>} filteredMarkers - The markers to display in the results.
   */
  showSearchResults(filteredMarkers) {
    const resultsList = $('.dealer-locator-search-results-list');
    resultsList.empty();

    const sortedMarkers = [...filteredMarkers].sort((elementA, elementB) => {
      const priorityA = elementA.location.Prio || Infinity;
      const priorityB = elementB.location.Prio || Infinity;

      return priorityA - priorityB;
    });

    const resultsHtml = sortedMarkers.map(({ location }, index) => {
      const name = location.Name ? `<strong>${location.Name}</strong><br />` : '';
      const street = location.Street ? `${location.Street}, ` : '';
      const city = location.Location ? `${location.Location}, ` : '';
      const zip = location.Zip ? `${location.Zip}<br />` : '<br />';
      const phone = location.Phone ? `<a href="tel:${location.Phone}">${location.Phone}</a><br />` : '';

      return `
        <li>
          ${name}
          ${street}${city}${zip}
          ${phone}
          <button class="dealer-locator-view-marker-btn" data-marker-index="${index}">></button>
        </li>`;
    }).join('');

    resultsList.html(resultsHtml);

    resultsList.off('click', '.dealer-locator-view-marker-btn').on('click', '.dealer-locator-view-marker-btn', (e) => {
      const markerIndex = $(e.target).data('marker-index');
      const { marker, location } = filteredMarkers[markerIndex];

      if (window.innerWidth < 992) {
        $('.dealer-locator-show-map').addClass('dealer-locator-result-selector-btn--active');
        $('.dealer-locator-show-results').removeClass('dealer-locator-result-selector-btn--active');
        $('.dealer-locator-search-results').fadeOut();
      }

      this.map.setCenter(marker.getPosition());
      this.map.setZoom(14);
      this.showInfoWindow(marker, location);
    });

    if (window.innerWidth < 992) {
      $('.dealer-locator-mobile-result-selector').fadeIn();
    }

    $('.dealer-locator-search-results').fadeIn();
  }

  /**
   * Attaches event listeners for the dealer locator map functionality.
   */
  attachEvents() {
    const isDesktop = window.innerWidth > 992;

    if (!isDesktop) {
      $('.dealer-locator-show-map').on('click', () => {
        $('.dealer-locator-show-map').addClass('dealer-locator-result-selector-btn--active');
        $('.dealer-locator-show-results').removeClass('dealer-locator-result-selector-btn--active');
        $('.dealer-locator-search-results').fadeOut();
      });

      $('.dealer-locator-show-results').on('click', () => {
        $('.dealer-locator-show-results').addClass('dealer-locator-result-selector-btn--active');
        $('.dealer-locator-show-map').removeClass('dealer-locator-result-selector-btn--active');
        $('.dealer-locator-search-results').fadeIn();
      });
    }

    $(document).on('click', '.dealer-locator-search', () => {
      this.displaySearchResults();
    });

    $('.dealer-locator-back-btn').on('click', () => {
      if (isDesktop) {
        $('.dealer-locator-search-results').fadeOut(() => {
          $('.dealer-locator-options').fadeIn();
        });
      } else {
        $('.dealer-locator-search-results').fadeOut();
      }
    });
  }
}

/**
 * Initializes the Dealer Locator Map functionality when the document is ready.
 */
$(document).ready(() => {
  if (!DealerLocatorMap.isOnPage()) {
    return;
  }

  /**
   * Dynamically loads the Google Maps API script if it has not been loaded already.
   * @param {string} googleMapsKey - The API key for Google Maps.
   */
  const loadGoogleMapsAPI = (googleMapsKey) => {
    if (!window.dlrLocatorInitMap) {
      function initMap() {
        const mapInstance = new DealerLocatorMap();
        mapInstance.initMap();
      }

      window.dlrLocatorInitMap = initMap;

      // Create and append the Google Maps API script
      let script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&libraries=places,geometry&callback=dlrLocatorInitMap&loading=async`;
      document.body.appendChild(script);
    }
  }

  /**
   * Fetches the Google Maps API key from the server and loads the API.
   */
  const fetchApiKey = () => {
    $.ajax({
      url: '/DealerLocator/apikey',
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .done((data) => {
        if (data.GoogleMapsApiKey) {
          loadGoogleMapsAPI(data.GoogleMapsApiKey);
        }
      })
  }

  fetchApiKey();
});
