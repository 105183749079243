import 'es6-object-assign/auto';

import { SiteSearch } from './common/siteSearch';
import { Dropdown } from './common/dropdown';
import { StickyMenu } from './common/stickyMenu';
import { ScrollToTop } from './common/scrollToTop';
import { ClickToScrollToElement } from './common/clickToScrollToElement';
import { Accordion } from './common/accordion';
import { Tabs } from './common/tabs';
import { IntroCarousel } from './carousel/introCarousel';
import { ProductCarousel } from './carousel/productCarousel';
import { BoxCarousel } from './carousel/boxCarousel';
import { PromoCarousel } from './carousel/promoboxCarousel';
import { CarouselAlt } from './carousel/carouselAlt';
import { CarouselGallery } from './carousel/carouselGallery';
import { MobileNav } from './common/mobileNav';
import { Select } from './common/select';
import { SelectMultiLevel } from './common/selectMultiLevel';
import { ShowMore } from './common/showMore';
import { Tooltip } from './common/tooltip';
import { Popup } from './common/popup';
import { Table } from './common/table';
import { IframePopup } from './common/iframePopup';
import { PopupHandler } from './sizeGuidePopup';
import { CarouselProtects } from './carousel/protectsCarousel';
import { ProductSupportingCarouselFrost } from './carousel/productSupportingCarouselFrost';
import { ProductList } from './carousel/product-list';
import { ProductsViewOptions } from './products/productViewOptions';
import { infoPanel } from './productFilterInfoPanel';
import { HeadingHandler } from './products/headingHandler';
import { HeaderScrollMobile } from './common/headerScrollMobile';
import { ExternalLinksMegaMenu } from './common/externalLinksMegaMenu';
import { ProductSupportingCarouselJobst } from './carousel/productSupportingCarouselJobst';
import { ProductComparePopup } from './products-compare-popup';
import { DealerLocatorMap } from './dealer-locator';
import { ImageLazyload } from './imageLazyload';
import { ProductReviewAccessibility } from './products/productReviewAccessibility';
import { ProductCardHandler } from './productCard';

/**
 * Common HMS functionalities
 */
window.HMSCommon = class HMSCommon {
   constructor() {
    this.init();
  }

  /**
   * Loops through classes array and inits the class if the hook element is present on page.
   * @param {Array} classes - Array of class imports
   */
  genericInit(classes) {
    classes.forEach(initClass => {
      if (initClass.isOnPage()) {
        new initClass();
      }
    });
  }

  /**
   * Init common js classes. Run on page load.
   */
  init() {
    new MobileNav();

    this.genericInit([
      Dropdown, StickyMenu, IntroCarousel, PromoCarousel, CarouselAlt, Popup, Table,
      CarouselGallery, BoxCarousel, Accordion, Tabs, ScrollToTop, Select, SelectMultiLevel, ShowMore, Tooltip,
      ProductCarousel, IframePopup, ClickToScrollToElement, SiteSearch, PopupHandler,
      ProductSupportingCarouselFrost, ProductList, ProductsViewOptions, infoPanel, CarouselProtects,
      HeaderScrollMobile, HeadingHandler, ExternalLinksMegaMenu, ProductSupportingCarouselJobst, ProductComparePopup,
      ImageLazyload, DealerLocatorMap, ProductReviewAccessibility, ProductCardHandler
    ]);
  }
}