import { Carousel } from './carousel';

/**
 * IntroCarousel is carousel component displayed in full width
 */
export class IntroCarousel extends Carousel {
  /**
   * css selector of carousel container
   * @static
   */
  static selector = '.js-intro-carousel';

  /**
   * Checks if container node is present in the dom
   * @function isOnPage
   * @returns {Boolean}
   * @static
   */
  static isOnPage() {
    return document.querySelector(IntroCarousel.selector);
  }

  constructor() {
    super();
    this.carousel = new Map();
    super.init(this, IntroCarousel.selector);
  }

  updateTabIndex(swiper) {
    $('.intro-carousel-link').attr('tabindex', '-1');
    $('.btn.intro-carousel-btn').attr('tabindex', '-1');
    const activeSlide = swiper.slides[swiper.activeIndex];
    $(activeSlide).find('.btn.intro-carousel-btn').attr('tabindex', '0');
    $(activeSlide).find('.intro-carousel-link').first().attr('tabindex', '0');
  }

  /**
   * Generate config depending of the number of the slides in carousel by extending the default config
   * @param {Node} $carousel - Carousel DOM Node
   * @returns {Object} extended config
   */
  generateConfig($carousel) {
    if (super.hasSingleSlide($carousel)) {
      return super.extendConfig({
        pagination: false,
        loop: false,
        autoplay: false
      });
    }

    const that = this;

    return super.extendConfig({
      autoplay: super.toggleAutoplay($carousel),
      pagination: {
        el: '.js-carousel-pagination-intro',
        clickable: true,
        type: 'bullets',
        renderBullet: (index, className) => {
          return `<button class="${className}" aria-current="false" data-index="${index + 1}"></button>`;
        }
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      on: {
        init: function () {
          that.updateTabIndex(this);

          // eslint-disable-next-line no-unused-expressions
          document.querySelector('.swiper-pagination-bullet-active')?.setAttribute('aria-current', true);
          const dataLabelValue = document.querySelector('.carousel-controls').getAttribute('data-aria-label') || '';

          document.querySelectorAll('.swiper-pagination-bullet').forEach(bullet => {
            const bulletIndex = bullet.getAttribute('data-index');
            bullet.setAttribute('aria-label', `${dataLabelValue} ${bulletIndex}`)
          });
        },
        slideChange: function () {
          that.updateTabIndex(this);

          document.querySelectorAll('.swiper-pagination-bullet').forEach(bullet =>
            bullet.setAttribute('aria-current', bullet.classList.contains('swiper-pagination-bullet-active') ? 'true' : 'false')
          );
        },
      },
    });
  }
}