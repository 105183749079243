/**
 * Class for enhancing the accessibility of product review elements.
 * This class ensures that elements related to product reviews are properly
 * labeled and focusable for better accessibility.
 */
export class ProductReviewAccessibility {
  /**
   * Always returns true so the class gets instantiated.
   *
   * This method must return `true` because Bazaarvoice and PowerReviews
   * are loaded asynchronously and are not immediately available in the DOM.
   * If we relied on checking elements here, the class would not be initialized
   * at the right time. Instead, the constructor handles the actual check
   * and determines whether to execute `enhanceAccessibility()` after a delay.
   *
   * @returns {boolean} Always returns `true`.
   */
  static isOnPage() {
    return true;
  }

  /**
   * Initializes accessibility enhancements with a single delay.
   *
   * Since Bazaarvoice and PowerReviews load asynchronously, we wait 5 seconds
   * before checking for the necessary elements. If they are found, the
   * `enhanceAccessibility()` method is executed. Otherwise, nothing happens.
   */
  constructor() {
    setTimeout(() => {
      if ($('.bv_main_container').length > 0 || $('.pr-snippet-stars').length > 0) {
        this.enhanceAccessibility();
      }
    }, 5000);
  }

  /**
   * Enhances the accessibility of product review elements.
   */
  enhanceAccessibility() {
    $('.bv_main_container').each((index, element) => {
      const $starsContainer = $(element);
      const labelValue = $starsContainer.find('.bv-off-screen').text().trim();

      if (labelValue) {
        $starsContainer.attr({
          'aria-hidden': 'false',
          'tabindex': '0',
          'aria-label': labelValue
        });
      }
    });

    $('.pr-snippet-stars').each((index, element) => {
      $(element).attr('tabindex', '0');
    });
  }
}
