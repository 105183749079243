import { eventKeyCodes } from './helpers/eventKeyCodes';

/**
 * Represents a class for handling click and accessibility on product card.
 */
export class ProductCardHandler {
  /**
   * Checks if the product card is present on the page.
   * @returns {boolean}
   */
  static isOnPage() {
    return $('.js-product-card').length !== 0;
  }

  constructor() {
    this.init();
  }

  init() {
    $('.js-product-card').on('click keydown', (event) => this.handleCardNavigation(event));
  }

  /**
   * Handles navigation when a product card is clicked or activated via the keyboard.
   * @param {Event} event - The event object from the click or keydown interaction.
   */
  handleCardNavigation(event) {
    const $target = $(event.target);
    const url = $(event.currentTarget).data('url');

    // Stop execution if the target is inside a comparison section or the view details section.
    if ($target.closest('.product-card-compare-content').length > 0 || $target.closest('.product-box-view-details').length > 0 ) {
      return;
    }

    if ((event.type === 'click' || event.keyCode === eventKeyCodes.enter) && url) {
      // Open in a new tab if Ctrl or Cmd (Mac) is pressed, otherwise navigate in the same tab.
      if (event.ctrlKey || event.metaKey) {
        window.open(url, '_blank');
      } else {
        window.location.href = url;
      }
    }
  }
}
