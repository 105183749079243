const playerSelector = '.js-video-popup';

export class VideoPopup {
  static isOnPage() {
    return $(playerSelector).length !== 0;
  }

  constructor() {
    this.load();
    this.init();
  }

  load() {
    /**
     * JW Player support for magnific popup
     */

    // Create a contained object instead of global variables
    var currentJwplayer = (function () {
      var self = {
        setCurrentPlayer(player) {
          this.player = player;
        },
        setCurrentPlayerElement(playerElement) {
          this.playerElement = playerElement;
        },
        getCurrentPlayer() {
          return this.player;
        },
        getCurrentPlayerElement() {
          return this.playerElement;
        }
      };

      return {
        setCurrentPlayer: self.setCurrentPlayer,
        setCurrentPlayerElement: self.setCurrentPlayerElement,
        getCurrentPlayer: self.getCurrentPlayer,
        getCurrentPlayerElement: self.getCurrentPlayerElement
      };
    })();

    $.magnificPopup.registerModule('jwplayer', {
      options: {
        markup:
          '<div class="mfp-jw-wrapper">' +
          '<button type="button" class="mfp-close">&times;</button>' +
          '<div id="mfp-jwplayer"></div>' +
          '</div>'
      },

      proto: {
        // eslint-disable-next-line no-empty-function
        initJwplayer: function () { },
        setupClonedButton: function (element) {
          currentJwplayer.setCurrentPlayerElement(element);

          // Create a dupe since this gets removed by the Platform Video Player
          var buttonClone = currentJwplayer.getCurrentPlayerElement().cloneNode(true);
          var parentToAddTo = currentJwplayer.getCurrentPlayerElement().parentNode;

          // Put the button copy where the removed button was
          parentToAddTo.appendChild(buttonClone);

          // Set new cloned button to be the player element
          currentJwplayer.setCurrentPlayerElement(buttonClone);
        },

        instantiatePlayer: function () {
          const urlHref = currentJwplayer.getCurrentPlayerElement().getAttribute('href');
          const urlHls = currentJwplayer.getCurrentPlayerElement().getAttribute('data-video-url-hls');
          const videoName = currentJwplayer.getCurrentPlayerElement().getAttribute('data-video-name');

          if (!(urlHref || urlHls || videoName)) {
            return;
          }
          // eslint-disable-next-line no-undef
          var player = new VideoPlayer(currentJwplayer.getCurrentPlayerElement(), {
            url: {
              progressive: urlHref,
              hls: urlHls
            },
            analytics: {
              name: videoName
            }
          });
          currentJwplayer.setCurrentPlayer(player);

          // Finish and play video
          player.playOnReady();
        },

        appendPlayerToPopup: function () {
          if (!currentJwplayer.getCurrentPlayer()) {
            return;
          }
          IsJwplayerContainerReady('#mfp-jwplayer', 100);
        },

        getJwplayer: function (item, template) {
          this.updateStatus('ready');
          var isDone = false;

          function magnificPopupSetup() {
            // Setup video and make sure it only does it once
            if (!isDone) {
              var clickedElement = item.el[0];
              $.magnificPopup.proto.setupClonedButton(clickedElement);
              $.magnificPopup.proto.instantiatePlayer();
              $.magnificPopup.proto.appendPlayerToPopup();

              if (!currentJwplayer.getCurrentPlayer()) {
                return;
              }

              isDone = true;
            }
          }

          this.ev.on('mfpOpen', function () {
            magnificPopupSetup();
          });

          this.ev.on('mfpChange', function () {
            magnificPopupSetup();
          });

          return template;
        }
      }
    });

    function uglyJwplayerWrapperHack() {
      var videoObj = $('.js-video-popup');
      var jwChildWithPop = videoObj.find($('.js-video-popup'));
      if (jwChildWithPop.length > 0) {
        jwChildWithPop.removeClass('js-video-popup');
      }
    }

    function IsJwplayerContainerReady(selector, time) {
      var mfpPlayer = document.querySelector(selector);
      if (mfpPlayer) {
        var playerElement = document.querySelector(
          '#' + currentJwplayer.getCurrentPlayer().element.id
        );
        if (mfpPlayer.hasChildNodes()) {
          mfpPlayer.removeChild(mfpPlayer.firstChild);
        }
        mfpPlayer.appendChild(playerElement);
      } else {
        setTimeout(function () {
          IsJwplayerContainerReady(selector, time);
        }, time);
      }
    }

    uglyJwplayerWrapperHack();
  }

  init() {
    $.each($(playerSelector), (index, player) => {
      if ($(player).parents('.image-carousel').length === 0) {
        const $player = $(player);
        let config = $player.hasClass('js-video-youtube') ? {
          type: 'iframe',
          iframe: {
            patterns: {
              azure: {
                index: 'azure.com',
                id: function () {
                  return 0;
                },
                src: 'http://www.azure.com/embed/%id%'
              }
            }
          }
        } : { type: 'jwplayer' };

        $player.on('keydown', (event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            $player.magnificPopup('open');
          }
        });

        return $player.magnificPopup(config);
      }
    });
  }
}